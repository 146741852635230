import React from "react";
import { Button } from "@mui/material";

const TemplateEditorButton = ({btnType,text,action, id}) => {
  return (
    <>
      <Button id={id} className={`temp_editor_cta ${btnType}`} onClick={action} >
        {text}
      </Button>
    </>
  );
};

export default TemplateEditorButton;

import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import HtmlCommentEditor from "./HtmlCommentEditor";
import LocationInput from "./LocationInput";
import AddImageButton from "./AddImageButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FlagIcon from "@mui/icons-material/Flag";
import EditIcon from "@mui/icons-material/Edit";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { BootstrapTooltip, timestamp } from "../../utils/services";
import { Fade, Paper, Popper } from "@mui/material";

const CommentsSection = (props) => {
  const {
    commentData,
    cmtRating,
    categoryId,
    lineId,
    cmtLocHolder,
    AllCategoryList,
    updateTemplate,
    MediaHolder,
    commentId,
  } = props;

  const [state, setState] = useState({
    editLocation: false,
    editText: "",
    isExpanded: true,
    // anchorEl: null,
  });
  const [mediaState, setMediaState] = useState({
    editMedia: false,
    isMediaExpanded: true,
    // anchorEl: null,
  });
  const { editLocation, editText, isExpanded } = state;
  const { editMedia, isMediaExpanded } = mediaState;
  const [placement, setPlacement] = React.useState();
  const [mediaPlacement, setMediaPlacement] = React.useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMediaEl, setAnchorMediaEl] = useState(null);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };
  const handleMediaClick = (newPlacement) => (event) => {
    setAnchorMediaEl(event.currentTarget);
    setMediaPlacement(newPlacement);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleMediaClickAway = () => {
    setAnchorMediaEl(null);
  };

  const open = Boolean(anchorEl);
  const mediaOpen = Boolean(anchorMediaEl);

  const [ratingValue, setRatingValue] = useState(commentData.defaultRating_id === undefined ? null : commentData.defaultRating_id);
  const [editorContent, setEditorContent] = useState(
    commentData.recommendation
  );
  const locationData = AllCategoryList?.template?.location_data;
  const imageData =
    commentData.comment_input_controls.length > 0 &&
    commentData?.comment_input_controls?.find((item) => item.type === "Media")
      ?.data;
  const location =
    commentData.comment_input_controls.length > 0 &&
    commentData?.comment_input_controls?.find(
      (item) => item.type === "Location"
    );
  const media =
    commentData.comment_input_controls.length > 0 &&
    commentData?.comment_input_controls?.find(
      (item) => item.type === "Media"
    );
  const ratingsMap = AllCategoryList?.template?.rating_data;
  const templateGuid = AllCategoryList.template.guid;
  let baseUrl = AllCategoryList.bucket_url;

  const selectedRat = ratingsMap.filter((rats) => {
    return rats.Id === ratingValue;
  });

  const tempMedia = MediaHolder[0];

  let lineIndex = null;
  let commentIndex = null;
  const catList = AllCategoryList?.template?.categories;
  const categoriesDataIndex = catList.findIndex(
    (cat) => cat.category_id === categoryId
  );
  if (categoriesDataIndex !== -1) {
    lineIndex = catList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    commentIndex = catList[categoriesDataIndex].lines[
      lineIndex
    ].comments.findIndex(
      (comment) => comment.comment_id === commentData.comment_id
    );
    if (commentIndex !== -1) {
      const isRating = catList[categoriesDataIndex].lines[lineIndex].comments[
        commentIndex
      ].comment_input_controls.some((item) => item.type === "Rating");
      if (!isRating) {
        cmtRating[0].data = selectedRat;
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].recommendation = editorContent;
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].updated_at = timestamp();
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].defaultRating_id = null;
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].comment_input_controls.push(cmtRating[0]);
      } else {
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].recommendation = editorContent;
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].updated_at = timestamp();
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].defaultRating_id = ratingValue === null ? ratingValue : Number(ratingValue);
        catList[categoriesDataIndex].lines[lineIndex].comments[
          commentIndex
        ].comment_input_controls.find((item) => item.type === "Rating").data = selectedRat;
      }
    }
  }

  const deleteImage = (pathId) => {
    const catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    const lineIndex = catList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    commentIndex = catList[categoriesDataIndex].lines[
      lineIndex
    ].comments.findIndex((comment) => comment.comment_id === commentId);
    let selectd =
      catList[categoriesDataIndex].lines[lineIndex].comments[commentIndex]
        .comment_input_controls;
    catList[categoriesDataIndex].lines[lineIndex].comments[commentIndex]
      .updated_at = timestamp();
    selectd.map((sel) => {
      if (sel.type === "Media") {
        sel.updated_at = timestamp();
        sel.data.map((med) => {
          if (med.id === pathId) {
            med.is_deleted = 1;
            med.updated_at = timestamp();
            updateTemplate("noToast");
          }
        });
      }
    });
  };

  const updateLocation = (type, remove) => {
    if (type === "edit") {
      setState((prevState) => ({
        ...prevState,
        editLocation: false,
        // anchorEl: null,
      }));
      setAnchorEl(null);
    } else {
      setState((prevState) => ({
        ...prevState,
        // anchorEl: null,
        isExpanded: remove ? false : true,
      }));
      setAnchorEl(null);
    }
    const catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    const lineIndex = catList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    commentIndex = catList[categoriesDataIndex].lines[
      lineIndex
    ].comments.findIndex((comment) => comment.comment_id === commentId);
    let selectd =
      catList[categoriesDataIndex].lines[lineIndex].comments[commentIndex]
        .comment_input_controls;
    const locationData = selectd.find((obj) => obj.type === "Location");
    if (locationData) {
      selectd.map((sel) => {
        if (sel.type === "Location") {
          if (type === "remove") {
            sel.is_disabled = remove;
            sel.updated_at = timestamp();
          }

          else {
            sel.prompt = editText;
            sel.is_manual_promt_edit = 1;
            sel.updated_at = timestamp();
          }
          updateTemplate("noToast");
        }
      });
    } else {
      let newData = cmtLocHolder;
      if (type === "remove") newData[0].is_disabled = remove;
      else {
        newData[0].prompt = editText;
        newData[0].is_manual_promt_edit = 1;
        newData[0].updated_at = timestamp();
      }
      selectd.push(newData[0]);
      updateTemplate("noToast");
    }
  };

  const updateMedia = (type, remove) => {
      setMediaState((prevState) => ({
        ...prevState,
        isMediaExpanded: remove ? false : true,
      }));
      setAnchorMediaEl(null);
    const categoryList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = categoryList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    const lineIndex = categoryList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    commentIndex = categoryList[categoriesDataIndex].lines[
      lineIndex
    ].comments.findIndex((comment) => comment.comment_id === commentId);
    let selected =
    categoryList[categoriesDataIndex].lines[lineIndex].comments[commentIndex]
        .comment_input_controls;
    const MediaData = selected.find((obj) => obj.type === "Media");
    if (MediaData) {
      selected.map((sel) => {
        if (sel.type === "Media") {
          if (type === "remove") {
            sel.is_disabled = remove;
            sel.updated_at = timestamp();
          }
          else {
            sel.prompt = editText;
            sel.is_manual_promt_edit = 1;
            sel.updated_at = timestamp();
          }
          updateTemplate("noToast");
        }
      });
    } else {
      let mediaData = MediaHolder;
      if (type === "remove") mediaData[0].is_disabled = remove;
      else {
        mediaData[0].prompt = editText;
        mediaData[0].is_manual_promt_edit = 1;
        mediaData[0].updated_at = timestamp();
      }
      selected.push(mediaData[0]);
      updateTemplate("noToast");
    }
  };

  const handleChangeRating = (e) => {
    setRatingValue(e.target.value);
    setTimeout(() => {
      updateTemplate("noToast");
    }, 800);
  };

  const inputRef = useRef(null);
  useEffect(() => {
    if (editLocation && inputRef.current) inputRef.current.focus();
    setRatingValue(commentData.defaultRating_id === undefined ? null : commentData.defaultRating_id);
  }, [editLocation, commentData]);

  return (
    <Grid>
      <div className="comment-section-wrapper">
        <p className="line-sub-head">Text</p>
        <HtmlCommentEditor
          update={updateTemplate}
          editorContent={editorContent}
          setEditorContent={setEditorContent}
        />
      </div>
      <div className="comment-section-wrapper">
        <p className="line-sub-head">Select Default rating</p>
        <Grid
          item
          xs={12}
          sm={7}
          md={6}
          style={{ marginTop: "6px", marginBottom: "20px" }}
        >
          <FormControl fullWidth className="field-reset template-rating-select">
            <Select
              labelId="rating-select-label"
              id="templ(ate-rating-select"
              size="small"
              displayEmpty
              value={ratingValue}
              onChange={handleChangeRating}
            >
              <MenuItem value={null}>
                <div className="isFlexCentreAlign rating_section">
                  <div className="rating_square"></div>
                  <span>Select rating</span>
                </div>
              </MenuItem>
              {ratingsMap?.map((rating, index) => {
                return (
                  <MenuItem key={rating.Id} value={rating.Id}>
                    <div className="isFlexCentreAlign rating_section">
                      <div
                        className="rating_square"
                        style={{ background: `${rating.Color}` }}
                      >
                        {(rating.include_in_summary === "1" ||
                          rating.include_in_summary === 1) && <FlagIcon />}
                      </div>
                      <span>{rating.Name}</span>
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </div>
      {AllCategoryList?.template?.is_texas !== 1 && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div style={{ padding: "10px" }}>
                    <MenuItem
                      style={{ padding: "8px" }}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          editLocation: true,
                          editText: location?.prompt || "DEFAULT LOCATION",
                        }));
                        setAnchorEl(null);
                      }}
                    >
                      <EditIcon className="editDescIcon" />
                      <Typography className="editDescText">
                        Edit Title
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      style={{ padding: "8px" }}
                      onClick={() => updateLocation("remove", 1)}
                    >
                      <RemoveCircleOutlineRoundedIcon className="editDescIcon" />
                      <Typography className="editDescText">
                        Remove Form Element
                      </Typography>
                    </MenuItem>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      {/* media */}
      {AllCategoryList?.template?.is_texas !== 1 && (
        <Popper
        open={mediaOpen}
        anchorEl={anchorMediaEl}
        placement={mediaPlacement}
        transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleMediaClickAway}>
                  <div style={{ padding: "10px" }}>

                    <MenuItem
                      style={{ padding: "8px" }}
                      onClick={() => updateMedia("remove", 1)}
                    >
                      <RemoveCircleOutlineRoundedIcon className="editDescIcon" />
                      <Typography className="editDescText">
                        Remove Form Element
                      </Typography>
                    </MenuItem>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}

      {
        <Grid container display={"flex"} mb={1}>
          <Grid item xs={12}>
            <Accordion
              expanded={location?.is_disabled ? false : isExpanded}
              className="accordionBox accordionSumFocus"
              onChange={() =>
                setState((prevState) => ({
                  ...prevState,
                  isExpanded: location?.is_disabled ? false : !isExpanded,
                }))
              }
            >
              <AccordionSummary className="accordionSumDesc accordionSumFocus">
                <Grid container gap={0.7}>
                  <Grid
                    item
                    xs={location?.is_disabled ? 11.2 : 9}
                    sm={location?.is_disabled ? 11.3 : 10.6}
                    md={location?.is_disabled ? 11.2 : 9.3}
                    lg={location?.is_disabled ? 11.3 : 10.6}
                  >
                    {editLocation ? (
                      <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={() => updateLocation("edit")}
                      >
                        <input
                          type="text"
                          name="title"
                          value={editText}
                          onKeyDown={(e) =>
                            e.keyCode === 13 && updateLocation("edit")
                          }
                          onFocus={(event) => event.target.select()}
                          ref={inputRef}
                          autoFocus
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              editText: e.target.value,
                            }))
                          }
                          className="text"
                        />
                      </ClickAwayListener>
                    ) : (
                      <Grid container display={"flex"} gap={0.1}>
                        <Grid
                          item
                          xs={!location?.is_disabled ? 12 : 2.4}
                          sm={!location?.is_disabled ? 11 : 2.4}
                          md={!location?.is_disabled ? 12 : 2.4}
                          lg={!location?.is_disabled ? 11 : 2.4}
                          display={"flex"}
                        >
                          <Typography
                            className={
                              location?.is_disabled ? "textDisable" : "text"
                            }
                          >
                            {location?.is_manual_promt_edit === 1
                              ? location?.prompt
                              : `DEFAULT LOCATION`}
                          </Typography>
                        </Grid>
                        {location?.is_disabled ? (
                          <Grid
                            item
                            xs={12}
                            sm={9.5}
                            md={12}
                            lg={9.5}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography className="titleDisable">
                              {`*This form element has been removed and will not be published.`}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    )}
                  </Grid>
                  {location?.is_disabled ? (
                    <Grid
                      item
                      xs={0.5}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <BootstrapTooltip title={<h1>Add form element</h1>}>
                        <AddCircleOutlineRoundedIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            updateLocation("remove", 0);
                          }}
                        />
                      </BootstrapTooltip>
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={1}
                        sm={0.5}
                        md={1}
                        lg={0.5}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        {!isExpanded ? (
                          <ExpandMoreIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              setState((prevState) => ({
                                ...prevState,
                                isExpanded: !isExpanded,
                              }));
                            }}
                          />
                        ) : (
                          <ExpandLessIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              setState((prevState) => ({
                                ...prevState,
                                isExpanded: !isExpanded,
                              }));
                            }}
                          />
                        )}
                      </Grid>
                      {AllCategoryList?.template?.is_texas !== 1 && (
                        <Grid
                          item
                          xs={1}
                          sm={0.5}
                          md={1}
                          lg={0.5}
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                        >
                          <MoreHorizIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              setAnchorEl(event.currentTarget);
                              handleClick("bottom-end")(event);
                            }}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <LocationInput
                  cmtLocHolder={cmtLocHolder}
                  lineName="coverings"
                  categoryId={categoryId}
                  lineId={lineId}
                  locationData={locationData}
                  AllCategoryList={AllCategoryList}
                  action="comment"
                  locationValue={commentData.default_location}
                  commentId={commentId}
                  updateTemplate={updateTemplate}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      }




      <Grid container display={"flex"} mb={1}>
        <Grid item xs={12}>
          <Accordion
            expanded={media?.is_disabled ? false : isMediaExpanded}
            className="accordionBox accordionSumFocus"
            onChange={() =>
              setMediaState((prevState) => ({
                ...prevState,
                isMediaExpanded: media?.is_disabled ? false : !isMediaExpanded,
              }))
            }
          >
            <AccordionSummary className="accordionSumDesc accordionSumFocus">
              <Grid container gap={0.7}>
                <Grid
                  item
                  xs={media?.is_disabled ? 11.2 : 9}
                  sm={media?.is_disabled ? 11.3 : 10.6}
                  md={media?.is_disabled ? 11.2 : 9.3}
                  lg={media?.is_disabled ? 11.3 : 10.6}
                >
                  {editMedia ? (
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      // onClickAway={() => updateLocation("edit")}
                    >
                      <input
                        type="text"
                        name="title"
                        value={editText}
                        onKeyDown={(e) =>
                          e.keyCode === 13 && updateLocation("edit")
                        }
                        onFocus={(event) => event.target.select()}
                        ref={inputRef}
                        autoFocus
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) =>
                          setMediaState((prevState) => ({
                            ...prevState,
                            // editText: e.target.value,
                          }))
                        }
                        className="text"
                      />
                    </ClickAwayListener>
                  ) : (
                    <Grid container display={"flex"} gap={0.1}>
                      <Grid
                        item
                        xs={!media?.is_disabled ? 12 : 2.4}
                        sm={!media?.is_disabled ? 11 : 2.4}
                        md={!media?.is_disabled ? 12 : 2.4}
                        lg={!media?.is_disabled ? 11 : 2.4}
                        display={"flex"}
                      >
                        <Typography
                          className={
                            media?.is_disabled ? "textDisable" : "text"
                          }
                        >
                          {media?.is_manual_promt_edit === 1
                            ? media?.prompt
                            : `MEDIA`}
                        </Typography>
                      </Grid>
                      {media?.is_disabled ? (
                        <Grid
                          item
                          xs={12}
                          sm={9.5}
                          md={12}
                          lg={9.5}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <Typography className="titleDisable">
                            {`*This form element has been removed and will not be published.`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
                {media?.is_disabled ? (
                  <Grid
                    item
                    xs={0.5}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <BootstrapTooltip title={<h1>Add form element</h1>}>
                      <AddCircleOutlineRoundedIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          updateMedia("remove", 0);
                        }}
                      />
                    </BootstrapTooltip>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={1}
                      sm={0.5}
                      md={1}
                      lg={0.5}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      {!isMediaExpanded ? (
                        <ExpandMoreIcon
                          onClick={(event) => {
                            event.stopPropagation();
                            setMediaState((prevState) => ({
                              ...prevState,
                              isMediaExpanded: !isMediaExpanded,
                            }));
                          }}
                        />
                      ) : (
                        <ExpandLessIcon
                          onClick={(event) => {
                            event.stopPropagation();
                            setMediaState((prevState) => ({
                              ...prevState,
                              isMediaExpanded: !isMediaExpanded,
                            }));
                          }}
                        />
                      )}
                    </Grid>
                    {AllCategoryList?.template?.is_texas !== 1 && (
                      <Grid
                        item
                        xs={1}
                        sm={0.5}
                        md={1}
                        lg={0.5}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <MoreHorizIcon
                          onClick={(event) => {
                            event.stopPropagation();
                            setAnchorMediaEl(event.currentTarget);
                            handleMediaClick("bottom-end")(event);
                          }}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid sx={{ mt: "20px" }} container className="template-image-wrapper">
                {imageData?.length > 0 &&
                  imageData?.map((img, index) => {
                    const url = baseUrl.concat(img?.onlineUrl);
                    return (
                      <div>
                        <img alt="img" src={url} key={index} />
                        <DeleteIcon
                          onClick={() => deleteImage(img.id)}
                          className="delete-wrappers"
                        />
                      </div>
                    );
                  })}
              </Grid>
              <AddImageButton
                templateGuid={templateGuid}
                isPlaceHolder={false}
                tempMedia={tempMedia}
                commentId={commentData.comment_id}
                categoryId={categoryId}
                lineId={lineId}
                from="comments"
                updateTemplate={updateTemplate}
                lineInputControlId={commentId}
                AllCategoryList={AllCategoryList}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>






    </Grid>
  );
};

export default CommentsSection;

import React from "react";
import { Grid } from "@mui/material";
import LocationInput from "./LocationInput";

const LocationSection = (props) => {
  const { locationData, AllCategoryList, categoryId, isPlaceHolder, item, lineId, lineInputControlId, updateTemplate } = props;
  const catList = AllCategoryList?.template?.categories;

  const locationsSelected = [];
  const locs =
    catList && catList.length > 0
      ? catList.forEach((cat) => {
          if (cat?.category_id === categoryId) {
            cat.lines.forEach((lin) => {
              if (lin?.line_id === lineId) {
                locationsSelected.push(lin?.default_location);
              }
            });
          }
        })
      : [];

  return (
    <Grid sx={{ marginTop: "12px" }}>
      {/* <Typography className="line-header">{prompt}</Typography> */}
      <p className="line-sub-head">DEFAULT LOCATION</p>
      <LocationInput lineName="coverings" isPlaceHolder={isPlaceHolder} item={item} locationData={locationData} AllCategoryList={AllCategoryList} categoryId={categoryId} lineId={lineId} locationValue={locationsSelected[0]} lineInputControlId={lineInputControlId} updateTemplate={updateTemplate} />
    </Grid>
  );
};

export default LocationSection;

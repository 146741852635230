import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import toast from "react-hot-toast";
import Chip from "@mui/material/Chip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FlagIcon from "@mui/icons-material/Flag";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
// common component imports
import { colors } from "../../config/app-constants";
import { BootstrapTooltip, timestamp } from "../../utils/services";
import Preloader from "../../helpers/Preloader";
import { apiCall } from "../../utils/action";
import TemplateEditorButton from "../button/TemplateEditorButton";
import AddDocument from "./TemplateModals/AddDocument";
import AddRating from "./TemplateModals/AddRating";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Modal, Box } from "@mui/material";

// styles imports
import "../../../assets/styles/containers/_templateEditorLayout2.scss";
import AlertImage from "../../../assets/images/alert.png";
import moment from "moment";
import MainTemplateModal from "./TemplateModals/MainTemplateModal";
import { useNavigate } from "react-router-dom";
// mock datas- to be removed after api integration

// mock datas- to be removed after api integration

function Details(props) {
  const { templateId, summaryEdit, summary, setSummary, LoadList, DiscardTemplateReq, version, templateName, updateTemplate, templateRatingDetails, PublishTemplate, templateDetails, TogglerSwicth, setAllCategoryList, AllCategoryList } = props;

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [templateDocument, setTemplateDocument] = useState([]);
  const [ratingName, setRatingName] = useState("");
  const [ratingNameError, setRatingNameError] = useState(false);
  const [ratingColor, setRatingColor] = useState("#82E01F");
  const [newRatingSummary, setNewRatingSummary] = useState("0");
  const [definition, setDefinition] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [attachments, setAttachments] = useState("");
  const [deleleRatingUsed, setDeleteRatingUsed] = useState(false);
  const [defaultRatingColors, setDefaultRatingColors] = useState([]);
  const [attachStatus, setAttachStatus] = useState();
  const [attId, setattId] = useState();
  // const [templateRatingDetails,setTemplateRatingDetails] = useState([]);
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(false);
  const [action, setAction] = useState("");
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [reRender, setReRender] = useState(false);
  const [createNewSite, setCreateNewSite] = React.useState(false);
  const isTexas = AllCategoryList?.template?.is_texas;
  const isSpectora = AllCategoryList?.template?.is_spectora;
  const navigate = useNavigate();
  const handleChange = (content) => {
    setSummary(content);
  };

  const HandleCreateSite = () => {
    setCreateNewSite(!createNewSite);
  };

  const AddSiteDetaill = () => {
    summaryEdit();
    HandleCreateSite();
  };

  let ratingArray = [];
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseRating = () => {
    setOpenRating(false);
  };

  const handleOpenEdits = (id) => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  // React.useEffect(() => { setSummary(AllCategoryList?.template?.summary_disclaimer) }, [Summary]);

  const addRating = () => {
    setAction("");
    setEditId("");
    setDeleteId("");
    setRatingName("");
    setRatingColor("#82E01F");
    setDefinition("");
    setNewRatingSummary("0");
    setIsDefaultTemplate(false);
    setOpenRating(true);
  };

  const editRating = (rating) => {
    setAction("edit");
    setEditId(rating.Id);
    setDeleteId("");
    setRatingName(rating.Name);
    setRatingColor(rating.Color);
    setDefinition(rating.Definition);
    setNewRatingSummary(rating.include_in_summary);
    setIsDefaultTemplate(rating.is_default);
    setOpenRating(true);
  };

  const deleteRating = (id, used) => {
    if (used === 1) {
      setDeleteRatingUsed(true);
    }
    setAction("delete");
    setDeleteId(id);
    setOpenDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteRatingUsed(false);
  }

  function validation(tempRatingName) {
    if (tempRatingName === "" || tempRatingName === null) setRatingNameError(true);
    else setRatingNameError(false);
    if (tempRatingName !== "" && tempRatingName !== null) return true;
    else return false;
  }
  // API calls
  const getTemplateData = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      template_id: templateId,
    };
    apiCall(
      (response) => {
        // console.log("GET_TEMPLATE_DATA", response);
        const { data, message, success } = response.data;
        if (success) {
          // get the default ratings if there are no ratings data associated with this template
          // setTemplateRatingDetails(data.template?.rating_data)
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          // console.log("else ", message);
        }
      },
      formdata,
      "GET_TEMPLATE_DATA"
    );
  };

  const addAttachmentModal = (open, type, id) => {
    setOpen(open);
    setAttachStatus(type);
    if (type === "edit") {
      setattId(id);
      setAttachments(templateDetails?.attachments);
    } else {
      setAttachments("");
    }
  };

  const getDefaultRatingColors = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        // console.log("GET_DEFAULT_RATING_COLOR", response);
        const { data, message, success } = response.data;
        if (success) {
          setDefaultRatingColors(data);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          // console.log("else ", message);
        }
      },
      formdata,
      "GET_DEFAULT_RATING_COLOR"
    );
  };
  const getLargestId = (ratingsArray)=>{
    let largestId = 0;
    ratingsArray.forEach(item => {
        let id = parseInt(item.Id);
        if (id > largestId) {
            largestId = id;
        }
    });
    return largestId
  }
  
  const handleRatingUpdate = (tempRatingName, tempDefinition) => {
    let valid = validation(tempRatingName);
    let tempRatings = [...templateRatingDetails];
    if (action === "delete") {
      let deleteIndex = tempRatings.findIndex((elem) => elem.Id === deleteId);

      if (deleteIndex !== -1) {
        tempRatings.splice(deleteIndex, 1);
        ratingArray = tempRatings.map((ratingData, index) => {
          return {
            Id: String(ratingData.Id),
            Name: ratingData.Name,
            Color: ratingData.Color,
            Definition: ratingData.Definition,
            include_in_summary: ratingData.include_in_summary,
            is_default: ratingData.is_default,
            updated_at: timestamp(),
          };
        });
      }
      // updateTemplateData(tempRatingName)
      setOpenDeleteModal(false);
    }
    if (valid) {
      handleCloseRating();
      setOpenDeleteModal(false);
      if (action === "edit") {
        let editObj = tempRatings.find((elem) => elem.Id === editId);
        editObj.Name = tempRatingName;
        editObj.Definition = tempDefinition;
        editObj.Color = ratingColor;
        editObj.include_in_summary = newRatingSummary;
        editObj.is_default = isDefaultTemplate;
        editObj.updated_at = timestamp();
      } else if (action === "delete") {
        let deleteIndex = tempRatings.findIndex((elem) => elem.Id === deleteId);
        if (deleteIndex !== -1) tempRatings.splice(deleteIndex, 1);
      } else {
        tempRatings.push({
          Id:String(getLargestId(templateRatingDetails)+1),
          Name: tempRatingName,
          Color: ratingColor,
          Definition: tempDefinition,
          include_in_summary: newRatingSummary,
          is_default: isDefaultTemplate,
          updated_at: timestamp(),
        });
      }

      if (tempRatings?.length !== 0 && tempRatings !== null) {
        ratingArray = tempRatings.map((ratingData, index) => {
          return {
            Id: String(ratingData.Id),
            Name: ratingData.Name,
            Color: ratingData.Color,
            Definition: ratingData.Definition,
            include_in_summary: ratingData.include_in_summary,
            is_default: ratingData.is_default,
            updated_at: timestamp(),
          };
        });
      }
      if (action === "delete") updateTemplateData("showToast", "Rating has been deleted", true);
      else if (action === "edit") updateTemplateData("showToast", "Rating has been saved", true);
      else updateTemplateData("showToast", "Rating has been added", true);
    }
  };

  const updateTemplateData = (toastStatus, toastMessage, dynamicToastFlag) => {
    toast.dismiss();
    setShowLoader(true);
    setAllCategoryList((prev) => {
      prev.template.rating_data = ratingArray;
      return prev;
    });
    const formdata = {
      template_id: templateId,
      template: {
        id: templateId,
        name: templateName,
        rating_data: ratingArray,
        updated_at: timestamp(),
      },
    };

    formdata.user_id = uuId;
    formdata.company_id = companyId;
    formdata.role_id = roleId;
    apiCall(
      (response) => {
        const { status_code,data, message, success } = response.data;
        if (success) {
          setShowLoader(false);
          if (toastStatus !== "noToast") {
            if (dynamicToastFlag) toast.success(toastMessage);
            else toast.success(message);
          }

          setReRender(!reRender);
          LoadList();
          setAction("");
        } else {
          toast.error(message);
          setShowLoader(false);
          if(status_code === 404){
            navigate(`/templates/`);
          }else if(status_code === 400 && message === "You can't edit template details after published."){
            navigate(`/templates/`);
          }
        }
      },
      formdata,
      "UPDATE_TEMPLATE_DATA"
    );
  };
  useEffect(() => {
    //  getTemplateData();
    getDefaultRatingColors();
  }, [reRender]);

  return (
    <>
      <Preloader showPreloader={showLoader} />
      {/* Add document modal */}
      <AddDocument handleClose={handleClose} open={open} setOpen={setOpen} LoadList={LoadList} templateId={templateId} companyId={companyId} uuId={uuId} roleId={roleId} attachments={attachments} attId={attId} attachStatus={attachStatus} templateDocument={templateDocument} setTemplateDocument={setTemplateDocument} />
      {/* Add rating modal */}
      <AddRating openRating={openRating} setOpenRating={setOpenRating} handleCloseRating={handleCloseRating} ratingName={ratingName} setRatingName={setRatingName} definition={definition} setDefinition={setDefinition} rating_colors={defaultRatingColors} ratingColor={ratingColor} setRatingColor={setRatingColor} newRatingSummary={newRatingSummary} setNewRatingSummary={setNewRatingSummary} templateId={templateId} handleRatingUpdate={handleRatingUpdate} isDefaultTemplate={isDefaultTemplate} setIsDefaultTemplate={setIsDefaultTemplate} ratingNameError={ratingNameError} />
      {/* delete modal */}
      <Dialog open={openDeleteModal} onClose={closeDeleteModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="delete-alert-modal">
        <DialogTitle id="alert-dialog-title">
          {"Delete rating?"}
          <CloseIcon className="close-icon" onClick={closeDeleteModal} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{deleleRatingUsed ? "This rating is used as a default rating for some comments in your template.\nDeleting the rating will remove the default rating for those comments." : "This action cannot be undone."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="delete-modal-cancel-btn" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button className="default-btn" variant="contained" color="error" onClick={handleRatingUpdate}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete modal end */}
      <div className="status status-container">
        <div className="status__layout status_wrapper">
          {/* default toggle button */}
          <div className="default_toggler mb-23">
            <div>
              <span style={{ display: "flex", alignItems: "center" }}>
                {templateDetails.default === 0 ? (
                  <ToggleOffIcon
                    id="default_template_off"
                    onClick={() => TogglerSwicth()}
                    style={{
                      fontSize: "36px",
                      color: "#BEBEBE",
                      cursor: "pointer",
                      marginRight: "7px",
                    }}
                  />
                ) : (
                  <ToggleOnIcon
                    onClick={() => TogglerSwicth()}
                    id="default_template_on"
                    style={{
                      fontSize: "36px",
                      cursor: "pointer",
                      color: "#239D80",
                      marginRight: "7px",
                    }}
                  />
                )}
                <span id="te-setasdefault" style={{ fontSize: "14px", color: "#333333" }}>
                  {" "}
                  Set as default template
                </span>
              </span>
              {/* <span>Set as default template</span> */}
            </div>
            <p>Your default template will be displayed first automatically.</p>
          </div>
          {/* status chip */}
          <div className="status_chip mb-23">
            <div>
              <span className="status-head">Status:</span>
              <span>
                <Chip
                  sx={{
                    // backgroundColor: colors[`${props.status.status.toUpperCase()}_BG`],
                    backgroundColor: templateDetails.is_published === 0 ? colors[`UNPUBLISHED_CHANGES_BG`] : templateDetails.is_published === 2 ? colors[`UNPUBLISHED_CHANGES_BG`] : colors[`PUBLISHED_BG`],
                    // color: colors[`${props.status.status.toUpperCase()}`],
                    color: templateDetails.is_published === 0 ? colors[`UNPUBLISHED_CHANGES`] : templateDetails.is_published === 2 ? colors[`UNPUBLISHED_CHANGES`] : colors[`PUBLISHED`],
                    height: "18px",
                  }}
                  label={
                    <h4 className="chip_text">
                      {templateDetails.is_published === 0 ? "unpublished changes" : templateDetails.is_published === 2 ? "Publish In-progress" : "published"}
                      {/* {props.status.status} */}
                    </h4>
                  }
                />
              </span>
            </div>
            <p>{templateDetails.is_published === 0 ? "This template contains unpublished changes. Until changes are published, they will not appear in new inspections." : "This template is published and ready for use."}</p>
            {/* <p>This template contains unpublished changes. Until changes are published, they will not appear in new inspections. </p> */}
          </div>
          {/* attachment  */}
          {/* <div className="attachment mb-23">
            <p>
              Attachments
              <span>
                <BootstrapTooltip
                  title={
                    <h1 className="font-13">
                      Use attachments to include additional files or information
                      with the inspection report when it is published.
                    </h1>
                  }
                >
                  <InfoOutlinedIcon className="info-icon" />
                </BootstrapTooltip>
              </span>
            </p>
            <div className="attachment_list">
              {templateDetails?.attachments?.map((elem, index) => {
                return (
                  <div key={index}>
                    <div className="attachment_list_item">
                      <span className="doc_title">{elem.file_name}</span>
                      <span className="list_action">
                        <span>
                          <BootstrapTooltip
                            onClick={() =>
                              addAttachmentModal(true, "edit", elem.id)
                            }
                            title={<h1 className="font-13">Edit details</h1>}
                          >
                            <EditIcon className="edit-icon" />
                          </BootstrapTooltip>
                        </span>
                        <span>
                          <BootstrapTooltip
                            title={<h1 className="font-13">Delete record</h1>}
                          >
                            <DeleteIcon className="delete-icon" />
                          </BootstrapTooltip>
                        </span>
                      </span>
                    </div>
                    <a href={elem.s3_url} target="_blank" download>
                      <span className="attachments">
                        <FileDownloadIcon /> {elem.s3_url.split("/")[3]}
                      </span>
                    </a>
                  </div>
                );
              })}
            </div>
            <div
              className="add_section"
              onClick={() => addAttachmentModal(true, "add")}
            >
              <ControlPointIcon className="add-icon" />
              Add attachment
            </div>
          </div> */}
          {/* Rating */}
          <div className="rating_section mb-23">
            <p>
              Ratings
              <span>
                <BootstrapTooltip title={<h1 className="font-13">Your rating choices. Flag icon will be sent to summary.</h1>}>
                  <InfoOutlinedIcon className="info-icon" />
                </BootstrapTooltip>
              </span>
            </p>
            {templateRatingDetails?.map((rating, index) => {
              return (
                <div className="rating_wrapper" key={index}>
                  <div className="rating_square_wrapper">
                    <div className="rating_square" style={{ background: `${rating.Color}` }}>
                      {(rating.include_in_summary === "1" || rating.include_in_summary === 1) && <FlagIcon />}
                    </div>
                    <span>{rating?.Name?.length > 25 ? `${rating.Name.substring(25, 0)}...` : rating.Name}</span>
                  </div>
                  <div
                    style={{
                      pointerEvents: isTexas && !isSpectora ? "none" : "auto",
                      opacity: isTexas && !isSpectora ? "0.7" : 1,
                    }}
                    className="list_action"
                  >
                    <span>
                      <BootstrapTooltip sx={{ pointerEvents: "none" }} title={<h1 className="font-13">Edit details</h1>}>
                        <EditIcon
                          id={`te-${rating?.Name}edit`}
                          className="edit-icon"
                          onClick={() => {
                            editRating(rating);
                          }}
                        />
                      </BootstrapTooltip>
                    </span>
                    <span>
                      <BootstrapTooltip sx={{ pointerEvents: "none" }} title={<h1 className="font-13">Delete</h1>}>
                        <DeleteIcon
                          className="delete-icon"
                          id={`te-${rating?.Name}delete`}
                          onClick={() => {
                            deleteRating(rating.Id, rating?.is_selected);
                          }}
                        />
                      </BootstrapTooltip>
                    </span>
                  </div>
                </div>
              );
            })}
            {isTexas && !isSpectora ? null : (
              <div
                style={{
                  pointerEvents: isTexas && !isSpectora ? "none" : "auto",
                  opacity: isTexas && !isSpectora ? "0.7" : 1,
                }}
                className="add_section mt-10"
                onClick={addRating}
                id="add_rating_btn"
              >
                <ControlPointIcon className="add-icon" />
                Add rating
              </div>
            )}
          </div>
          <div className="rating_section mt-34">
            <div className="rating_wrapper" style={{ alignItems: "center" }}>
              <p>Summary Disclaimer</p>
              <a onClick={() => HandleCreateSite()} style={{ marginBottom: "9px" }}>
                <span
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    color: "#4282e2",
                    fontWeight: "600 !important",
                  }}
                  className="attachments"
                >
                  <EditIcon
                    sx={{
                      fontSize: "12px !important",
                      position: "relative",
                      top: "2px",
                      right: "2px",
                    }}
                  />
                  {"Edit text"}
                </span>
              </a>
            </div>
            <div className="rating_wrapper">
              <span style={{ fontSize: "14px !important" }}>{summary?.length > 120 ? `${summary?.replace(/<[^>]*>|&nbsp;/g, "", "")?.substring(120, 0)}...` : summary?.replace(/<[^>]*>|&nbsp;/g, "", "")}</span>
            </div>
          </div>
          {/* Action buttons */}
          <div className="status_actions">
            <div className="status_cta">
              {templateDetails.version !== 0 && templateDetails.is_published === 0 && <TemplateEditorButton btnType="cta_normal" text="Discard draft" action={handleOpenEdits} />}
              <TemplateEditorButton id="te-savechangesbutton" btnType="cta_normal" text="Save changes" action={() => updateTemplate("showToast", "Template Data has been updated successfully", true)} />
              <TemplateEditorButton id="te-saveandpublishbutton" btnType="cta_filled" text="Save and publish" action={PublishTemplate} />
            </div>
            <div className="last_save_notify">
              <p>Last autosave: {moment(templateDetails.updated_at).calendar()}</p>
              <p>Changes to these settings only affect future reports.</p>
            </div>
          </div>
        </div>
      </div>

      <MainTemplateModal
        createNewSite={createNewSite}
        title={"Summary Disclaimer"}
        type={"summary"}
        HandleCreateSite={HandleCreateSite}
        actions={{
          AddSiteDetaill: () => AddSiteDetaill(),
        }}
        innerTemplate={
          <>
            <div
              className="te-editor-container"
              style={{
                height: "160px",
                width: "100%",
                marginTop: "29px",
                marginLeft: "16px",
                borderBottom: "1px solid #dadada",
              }}
            >
              <p className="line-sub-head">Introductory text</p>

              <SunEditor
                setDefaultStyle="font-size: 14px"
                height="100px"
                setOptions={{
                  buttonList: [["bold", "italic", "underline", "list", "fontColor", "fontSize"]],
                  spaceTag: null,
                }}
                onChange={handleChange}
                defaultValue={summary}
              />
            </div>
          </>
        }
      />

      <Modal open={openEdit} onClose={handleCloseEdit} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "655px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleCloseEdit} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", mt: "60px" }}>
              <img src={AlertImage} alt="alert" style={{ width: "51px", height: "49px" }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                {`The template: `}
                {<b style={{ fontSize: "22px", wordWrap: "break-word" }}>{templateDetails?.name} </b>}
                {` has not been published`}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                If you continue, any work you have done will be lost. Do you want to continue and discard the work you have done?{" "}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "right",
                mt: 8,
                mr: "34px",
                pb: "25px",
              }}
            >
              <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px" }} onClick={handleCloseEdit}>
                Cancel
              </Button>
              <Button variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "119px", ml: 3 }} onClick={() => DiscardTemplateReq()}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default Details;

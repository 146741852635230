import React, { useState } from "react";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import TemplateAccord from "../../../common/TemplateUtils/TemplateAccord";
import DefaultDetails from "./DefaultDetails";
import CategoryLists from "./CategoryLists";
import TemplateAdd from "../../../common/TemplateUtils/TemplateAdd";
import NewCategory from "../TemplateModals/NewCategory";
import MainTemplateModal from "../TemplateModals/MainTemplateModal";
import { apiCall } from "../../../utils/action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import uuid from "react-uuid";
import moment from "moment";
import { animate } from "motion";

import { timestamp } from "../../../utils/services";
import { AnimatePresence, motion } from "framer-motion";


const InspectionDetails = (props) => {
  const {
    CategoryList,
    AllCategoryList,
    newList,
    setNewList,
    LoadList,
    checkActiveFocus,
    isFocused,
    updateTemplate,
    renId,
    renamingId,
    isChanged,
    setSkeletonLoad,
    skeletonLoad,
    activeRename,
    outsideClick,
    TriggerClick,
    templateId,
    setShowLoader
  } = props;

  const [accordOpen, setAccordOpen] = React.useState(false);
  const [OpenCategory, setOpenCategory] = useState(false);
  const [sel, setSel] = useState([]);
  const [descArray, setDescArray] = useState([]);
  const [newdesc, setNewDesc] = useState([]);
  const [menu, setMenu] = React.useState(false);
  const [checked, setChecked] = React.useState();
  const [duplicateCategoryId, setDuplicateCategoryId] = useState(false);

  const stopActive = (event) => event.stopPropagation();

  React.useEffect(() => {
    setDescArray(CategoryList);
  }, [CategoryList]);

  const unique_id = uuid();
  const small_id = unique_id;

  const handleChanges = (index) => {
    const newItems = [...newList];

    newItems.map((item) => {
      if (item.uuid === index) {
        item.is_selected = !item.is_selected
      }
    })

    // newItems[index].is_selected = !newItems[index].is_selected;
    setNewList(newItems);
    // category.is_selected = e.target.checked;
    // setChecked(e.target.checked);
    setSel(newList);

  };

  const handleAddCategory = () => {
    setShowLoader(true)
    const blankCategory = {
      uuid: small_id,
      prompt: "",
      introductory_text: null,
      is_selected: false,
      order: AllCategoryList.template.categories.length + 1,
      is_deleted: 0,
    };
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      template_id: templateId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setNewList([blankCategory, ...data]); // adding blank category oject and category list from api response together to the state
          setShowLoader(false);
        } else {
          toast.error(response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_CATEGORIES_LIST"
    );
    setOpenCategory(!OpenCategory);
    // setNewList([blankCategory, ...newList])
    // newList.unshift(blankCategory);
    for (let i = 0; i < newList.length; i++) {
      newList[i].is_selected = false;
    }
    setChecked();
    setSel([]);
  };



  const handleCloseCat = () => {
    newList.splice(0, 1);
    setOpenCategory(!OpenCategory);
    setChecked();
    setSel([]);
  };

  const AddSiteDetaill = () => {
    const newArr = sel.filter((cat) => {
      return cat.is_selected === true;
    });
    // commenting the below lines because for new categories from list category_id should 
    // be added from the get-category-list api id and not unique id which was done earlier

    // const newArr2 = newArr.map((v) =>
    //   Object.assign(v, { category_id: small_id })
    // );
    if (newArr.length > 0) {
      newList.splice(0, 1);
      const datas = CategoryList.concat(newArr);
      AllCategoryList.template.categories = datas;
      AllCategoryList.template.categories.updated_at = timestamp();
      updateTemplate("noToast");
      setSel([]);
      setChecked();
      setOpenCategory(!OpenCategory);
    } else {
      newList.splice(0, 1);
      setOpenCategory(!OpenCategory);
      setChecked();
    }
  };
  document.querySelectorAll("section > div").forEach((item) => {
    animate((item, { opacity: [0, 1, 1, 0] }), {
      target: item,
      offset: ["start end", "end end", "start start", "end start"]
    })
  });

  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      descArray,
      result.source.index,
      result.destination.index
    );

    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      x.updated_at = timestamp();
      return x
    });


    setDescArray(reorderedItems);
    let catList = AllCategoryList?.template?.categories;
    catList = descArray
    updateTemplate("noToast");

  };

  const [active, setActive] = React.useState(false);
  const [id, setId] = React.useState("");

  const disableDnD = (isOpen, id) => {
    setActive(isOpen);
    setId(id);
  };



  return (
    <>
      <Grid className="inspection">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="InspDetails">
            {(provided) => (
              <div ref={provided.innerRef}>
                <AnimatePresence initial={false}>
                  {descArray.map((lists, index) => {
                    return (
                      <motion.div
                        key={lists?.category_id}
                        onAnimationStart={() => setSkeletonLoad(false)}
                        initial={{ opacity: 0, scale: 1, y: -50 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ opacity: 0, scale: 1, x: -50 }}
                      >
                        <Draggable
                          draggableId={String(lists.category_id)}
                          isDragDisabled={active && lists.category_id === id}
                          key={lists.category_id}
                          index={index}
                        >
                          {(provided) => (

                            <div
                              key={index}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              {
                                <CategoryLists
                                  disableDnD={(isOpen, id) =>
                                    disableDnD(isOpen, id)
                                  }
                                  isFocused={isFocused}
                                  checkActiveFocus={checkActiveFocus}
                                  AllCategoryList={AllCategoryList}
                                  category={lists}
                                  isChanged={isChanged}
                                  id={id}
                                  setDuplicateCategoryId={setDuplicateCategoryId}
                                  duplicateCategoryId={duplicateCategoryId}
                                  duplicateIndex={index}
                                  renId={renId}
                                  skeletonLoad={skeletonLoad}
                                  setSkeletonLoad={setSkeletonLoad}
                                  isInspection={lists.is_inspection_details}
                                  renamingId={(id) => renamingId(id)}
                                  activeRename={activeRename}
                                  TriggerClick={TriggerClick}
                                  outsideClick={outsideClick}
                                  updateTemplate={updateTemplate}
                                  LoadList={LoadList}
                                />
                              }
                            </div>
                          )}
                        </Draggable>
                      </motion.div>
                    );
                  })}
                </AnimatePresence>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <TemplateAdd
          HandleCreateSite={handleAddCategory}
          name={"Add category"}
        />
      </Grid>

      <MainTemplateModal
        newList={newList}
        createNewSite={OpenCategory}
        title={"Add category"}
        type={"newcat"}
        HandleCreateSite={handleCloseCat}
        actions={{
          AddSiteDetaill: () => AddSiteDetaill(),
        }}
        innerTemplate={
          <NewCategory
            newList={newList}
            handleChange={(index) => handleChanges(index)}
            checked={checked}
          />
        }
      />
    </>
  );
};

export default InspectionDetails;